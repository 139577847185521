import { pathToRegexp, compile } from "path-to-regexp"
import { stringify, parse } from "qs"
import { pipe, find, isNil } from "ramda"

import { RouteNotFoundError } from "lib/core/errors/route-not-found.error"
import { throwError, capitalize } from "lib/core/utils"
import { hasSavedForms } from "lib/core/forms.utils"

export const PUPIL_DETAILS_ROUTE_NAME = "pupilDetails"
export const PUPIL_DETAILS_NATIONAL_SCORE_PARAMETER_NAME = "citoScoreId"
export const PUPIL_DETAILS_METHOD_SCORE_PARAMETER_NAME = "methodScoreId"
export const GROUP_DETAILS_NATIONAL_SCORE_PARAMETER_NAME = "selectedCitoScoreId"
export const GROUP_DETAILS_METHOD_SCORE_PARAMETER_NAME = "methodId"
export const ENTITY_BASE_ROUTE_NAME = {
  GROUPS: "groups",
  GRADES: "years",
}
export const HOMEPAGE_NAMES_TO_ROUTE_NAMES = {
  SCHOOLS_LIST: "schoolsList",
  SCHOOL: "school",
  GROUPS_LIST: "groups",
  GROUP: "group",
}

/**
 * Utility lib for compiling and referencing routes by name, not hardcoding
 * the actual URL inside <Link>, <Router> or history.push.
 *
 * Example:
 *
 *  import * as RouterUtils from "lib/core/router.utils"
 *
 *  <Link
 *    to={ RouterUtils.build( "school", {
 *      schoolId: 72,
 *    } ) }>
 *    lorem ipsum link
 *  </Link>
 */

// Available app routes
export const routes = {
  admin__templates: "/app/schools/:schoolId/admin/templates",
  admin__permissions: "/app/schools/:schoolId/admin/permissions",
  admin__pupils: "/app/schools/:schoolId/admin/pupils",
  admin__upload: "/app/schools/:schoolId/admin/upload",

  account__login: "/auth/login",
  account__invite: "/auth/accept-invite/:invitationKey",
  account__edit: "/app/account",

  [ENTITY_BASE_ROUTE_NAME.GRADES]: "/app/schools/:schoolId/years",
  yearDetails: "/app/schools/:schoolId/years/:yeargradeId",

  [ENTITY_BASE_ROUTE_NAME.GROUPS]: "/app/schools/:schoolId/groups",
  group: "/app/schools/:schoolId/groups/:yearclassId",
  groupWorksheet: "/app/schools/:schoolId/groups/:yearclassId/worksheet",
  groupResults: "/app/schools/:schoolId/groups/:yearclassId/results",
  groupEmotional: "/app/schools/:schoolId/groups/:yearclassId/social-emotional",
  groupGoals: "/app/schools/:schoolId/groups/:yearclassId/goals",
  groupNotes: "/app/schools/:schoolId/groups/:yearclassId/notes",

  // wildcard regexp to extract params from any group sub routes
  groupsProxy: "/app/schools/:schoolId/groups/:yearclassId/(.*)",

  pupils: "/app/schools/:schoolId/pupils",
  [PUPIL_DETAILS_ROUTE_NAME]: "/app/schools/:schoolId/pupils/:pupilId",
  pupilSocialEmotional:
    "/app/schools/:schoolId/pupils/:pupilId/social-emotional",

  schoolsList: "/app/schools",
  school: "/app/schools/:schoolId",
  schoolSubject: "/app/schools/:schoolId/subjects/:subjectId",
  schoolSubjectDetails:
    "/app/schools/:schoolId/subjects/:subjectId/details/:schoolCalendarYear/:yearTime",

  notFound: "/not-found",
  appNotFound: "/app/not-found",
}

/**
 * Pass all available routes through pathToRegexp lib (the same lib that
 * React Router 4 uses to build the routes internally)
 *
 * @type {Object<string, Object>}
 */
const compiledRoutes = Object.entries(routes).reduce(
  (acc, [keyName, valuePath]) => ({
    [keyName]: compile(valuePath),
    ...acc,
  }),
  {},
)

/**
 * Get route URL by name
 *
 * @param  {string}  name  Route name
 *
 * @throws {RouteNotFoundError}  If route name not defined
 *
 * @return {string}  { description_of_the_return_value }
 */
export const get = (name) =>
  isNil(routes[name]) ? throwError(new RouteNotFoundError(name)) : routes[name]

/**
 * Build the URL based on name & available params
 *
 * @param  {String}  name    Route name
 * @param  {Object}  params  Route parameters
 * @param  {Object}  query   Query parameters
 *
 * @throws {RouteNotFoundError}  If route name not defined
 *
 * @return {String}  { description_of_the_return_value }
 */
export const build = (name, params = {}, query = null) =>
  isNil(routes[name])
    ? throwError(new RouteNotFoundError(name))
    : query === null
      ? compiledRoutes[name](params)
      : `${compiledRoutes[name](params)}?${stringify(query)}`

/**
 * Get parsed query parameters from url search string
 *
 * @param  {string}  search       The search string
 * @param  {Object}  parseParams  Parser lib parameters
 *
 * @return {Object}
 *
 * Example:
 *
 * parseQuery( "a=2" )   // { a: "2" }
 * parseQuery( "a.b=2" ) // { a: { b: "2" } }
 * parseQuery( "a[]=2" ) // { a: [ "2" ] }
 */
export const parseQuery = (search, parseParams = { allowDots: true }) =>
  parse(search.replace("?", ""), parseParams)

/**
 * Get the url parameters
 *
 * @param  {string}   url        The url
 * @param  {string}   routeName  The route name
 * @param  {Object}   arg3       Props
 * @param  {boolean}  arg3.end   The end
 *
 * @return {Object}   Key/Value parameter object
 */
export const getParams = (url, routeName, { end = false } = {}) => {
  const keys = []
  const regExp = pathToRegexp(routes[routeName], keys, { end })
  const match = regExp.exec(url)

  return isNil(match)
    ? {}
    : keys.reduce(
        (acc, key, index) => ({
          ...acc,
          [key.name]: match[index + 1],
        }),
        {},
      )
}

/**
 * Get parsed query parameters from url search string
 *
 * @param  {Object}  queryObject       The search string
 * @param  {*}  stringifyParams  Parser lib parameters
 *
 * @return {string}
 */
export const createQueryString = (queryObject, stringifyParams = {}) =>
  stringify(queryObject, stringifyParams)

/**
 * { lambda_description }
 *
 * @param  {<type>}  pathname  The pathname
 *
 * @return {<type>}  { description_of_the_return_value }
 */
export const findByPathname = (pathname) =>
  pipe(
    Object.entries,
    find(([, routePath]) => {
      const keys = []
      const regExp = pathToRegexp(routePath, keys, { end: true })
      const match = regExp.exec(pathname)

      return !isNil(match)
    }),
    ([routeName]) => routeName,
  )(routes)

/**
 * Gets the homepage URL
 *
 * @param  {Object}   arg1               Props
 * @param  {number}   arg1.schoolId      The school identifier
 * @param  {number}   arg1.groupId       The group identifier
 * @param  {boolean}  arg1.hasOneSchool  Indicates if one school
 * @param  {boolean}  arg1.hasOneGroup   Indicates if one group
 *
 * @return {string}   The homepage URL
 */
export const getHomepageURL = ({
  schoolId,
  groupId,
  hasOneSchool,
  hasOneGroup,
}) =>
  hasOneGroup
    ? build("groupWorksheet", {
        schoolId,
        yearclassId: groupId,
      })
    : hasOneSchool && !hasOneGroup
      ? build("groups", {
          schoolId,
        })
      : hasOneSchool
        ? build("school", {
            schoolId,
          })
        : build("schoolsList")

/**
 * Return URL query string without specified params
 *
 * @param  {string}   query       The search string
 * @param  {string[]} params        The list of query parameters names
 *
 * @return {string}   The result search string
 */
export const clearQuery = (query, params) => {
  const currentQueryParams = parseQuery(query)

  const cleanedParams = params.reduce((acc, parameter) => {
    if (Object.prototype.hasOwnProperty.call(acc, parameter)) {
      delete acc[parameter]
    }

    return acc
  }, currentQueryParams)

  return createQueryString(cleanedParams)
}

export const getPageName = (path) => {
  const routePath = Object.keys(routes).find((key) => routes[key] === path)

  if (routePath) {
    return capitalize(routePath.replace(/([a-z0-9])([A-Z])/g, "$1 $2"))
  }
}
// Get condition for ignoring Prompt component for school pages navigation
export const getSchoolPageNotBlockingCondition = (
  location,
  nextLocation,
  latestYear,
) => {
  if (!hasSavedForms()) {
    return false
  }
  const currentRoute = findByPathname(location.pathname)
  const nextRoute = findByPathname(nextLocation.pathname)
  const currentLocationParams = getParams(location.pathname, currentRoute)
  const nextLocationParams = getParams(nextLocation.pathname, nextRoute)
  const isTheSameSubject =
    currentLocationParams.subjects === nextLocationParams.subjects

  if (
    isTheSameSubject &&
    currentRoute === "schoolSubject" &&
    nextRoute === "schoolSubjectDetails"
  ) {
    return parseInt(nextLocationParams.schoolCalendarYear, 10) === latestYear
  } else if (
    isTheSameSubject &&
    nextRoute === "schoolSubject" &&
    currentRoute === "schoolSubjectDetails"
  ) {
    return parseInt(currentLocationParams.schoolCalendarYear, 10) === latestYear
  } else if (
    isTheSameSubject &&
    nextRoute === "schoolSubjectDetails" &&
    currentRoute === "schoolSubjectDetails"
  ) {
    return (
      parseInt(currentLocationParams.schoolCalendarYear, 10) ===
      parseInt(nextLocationParams.schoolCalendarYear, 10)
    )
  }
  return false
}
