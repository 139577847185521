import { createReducer } from "@reduxjs/toolkit"
import { upsertObjectInList } from "entities/pupils/pupils.reducers"
import { partition } from "ramda"

export const initialState = {
  methodScores: [],
  citoScores: [],
  pupils: [],
  results: [],
  groupNotes: [],
  yearclassTestMomentNotes: [],
  isYearclassLoading: false,
  isPupilAndMethodResultsLoading: false,
  isYearclassTestMomentResultsLoading: false,
  isNoteSaving: false,
  errors: null,
  isApproachesLoading: false,
  isApproachesUpdating: false,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_YEARCLASS_REQUEST", (state) => ({
      ...state,
      isYearclassLoading: true,
    }))
    .addCase("GET_YEARCLASS_SUCCESS", (state, action) => {
      const [yearclass, pupils, methodsList] = action.payload

      return {
        ...state,
        ...yearclass,
        pupils,
        methodsList,
        isYearclassLoading: false,
      }
    })
    .addCase("GET_YEARCLASS_FAILURE", (state) => ({
      ...state,
      isYearclassLoading: false,
    }))
    .addCase("GET_YEARCLASS_RESULTS_REQUEST", (state) => ({
      ...state,
      isPupilAndMethodResultsLoading: true,
      errors: null,
    }))
    .addCase("GET_YEARCLASS_RESULTS_SUCCESS", (state, action) => {
      const [
        pupilsWithCitoScoreHistory,
        pupilsWithMethodScoreHistory,
        { methodScores, yearclassMethodTestNotes },
      ] = action.payload

      const pupilsWithScores = state.pupils.map((pupil) => {
        const matchingCitoPupil = pupilsWithCitoScoreHistory.find(
          ({ id }) => id === pupil.id,
        )
        const matchingMethodPupil = pupilsWithMethodScoreHistory.find(
          ({ id }) => id === pupil.id,
        )

        return {
          ...pupil,
          scores: matchingCitoPupil ? matchingCitoPupil.scores : [],
          methodScores: matchingMethodPupil
            ? matchingMethodPupil.methodScores
            : [],
        }
      })

      return {
        ...state,
        pupils: pupilsWithScores,
        yearclassMethodTestNotes,
        methodScores,
        isPupilAndMethodResultsLoading: false,
      }
    })
    .addCase("GET_YEARCLASS_RESULTS_FAILURE", (state, action) => ({
      ...state,
      errors: action.payload,
      isPupilAndMethodResultsLoading: false,
    }))
    .addCase("GET_YEARCLASS_NATIONAL_TEST_RESULTS_REQUEST", (state) => ({
      ...state,
      isYearclassTestMomentResultsLoading: true,
      errors: null,
    }))
    .addCase("GET_YEARCLASS_NATIONAL_TEST_RESULTS_SUCCESS", (state, action) => {
      const { citoScores, yearclassTestMomentNotes } = action.payload

      return {
        ...state,
        citoScores,
        yearclassTestMomentNotes,
        isYearclassTestMomentResultsLoading: false,
      }
    })
    .addCase(
      "GET_YEARCLASS_NATIONAL_TEST_RESULTS__FAILURE",
      (state, action) => ({
        ...state,
        errors: action.payload,
        isYearclassTestMomentResultsLoading: false,
      }),
    )
    .addCase("UPDATE_PUPIL_OBSERVATION_SUCCESS", (state, action) => {
      const updateObservation = (pupil) => ({
        ...pupil,
        observations: upsertObjectInList(action.payload, pupil.observations),
      })
      const updatePupils = (pupils) =>
        pupils.map((pupil) =>
          pupil.id === action.payload.pupilId
            ? updateObservation(pupil)
            : pupil,
        )
      return {
        ...state,
        pupils: updatePupils(state.pupils),
      }
    })
    .addCase("CREATE_YEARCLASS_TEST_MOMENT_NOTE_REQUEST", (state) => ({
      ...state,
      isNoteSaving: true,
    }))
    .addCase("CREATE_YEARCLASS_TEST_MOMENT_NOTE_SUCCESS", (state, action) => ({
      ...state,
      yearclassTestMomentNotes: [
        ...state.yearclassTestMomentNotes,
        action.payload,
      ],
      isNoteSaving: false,
    }))
    .addCase("DELETE_YEARCLASS_TEST_MOMENT_NOTE_SUCCESS", (state, action) => ({
      ...state,
      yearclassTestMomentNotes: [
        ...state.yearclassTestMomentNotes.filter(
          (note) => note.id !== action.payload,
        ),
      ],
    }))
    .addCase("EDIT_YEARCLASS_TEST_MOMENT_NOTE_REQUEST", (state) => ({
      ...state,
      isNoteSaving: true,
    }))
    .addCase("EDIT_YEARCLASS_TEST_MOMENT_NOTE_SUCCESS", (state, action) => ({
      ...state,
      yearclassTestMomentNotes: [
        ...state.yearclassTestMomentNotes.map((note) => {
          if (note.id === action.payload.id) {
            return action.payload
          }

          return note
        }),
      ],
      isNoteSaving: false,
    }))

    .addCase("CREATE_YEARCLASS_METHOD_TEST_NOTE_REQUEST", (state) => ({
      ...state,
      isNoteSaving: true,
    }))
    .addCase("CREATE_YEARCLASS_METHOD_TEST_NOTE_SUCCESS", (state, action) => ({
      ...state,
      yearclassMethodTestNotes: [
        ...state.yearclassMethodTestNotes,
        action.payload,
      ],
      isNoteSaving: false,
    }))
    .addCase("CREATE_YEARCLASS_METHOD_TEST_NOTE_FAILURE", (state) => ({
      ...state,
      isNoteSaving: false,
    }))
    .addCase("DELETE_YEARCLASS_METHOD_TEST_NOTE_SUCCESS", (state, action) => ({
      ...state,
      yearclassMethodTestNotes: [
        ...state.yearclassMethodTestNotes.filter(
          (note) => note.id !== action.payload,
        ),
      ],
    }))
    .addCase("EDIT_YEARCLASS_METHOD_TEST_NOTE_REQUEST", (state) => ({
      ...state,
      isNoteSaving: true,
    }))
    .addCase("EDIT_YEARCLASS_METHOD_TEST_NOTE_SUCCESS", (state, action) => ({
      ...state,
      yearclassMethodTestNotes: [
        ...state.yearclassMethodTestNotes.map((note) => {
          if (note.id === action.payload.id) {
            return action.payload
          }

          return note
        }),
      ],
      isNoteSaving: false,
    }))
    .addCase("EDIT_YEARCLASS_METHOD_TEST_NOTE_FAILURE", (state) => ({
      ...state,
      isNoteSaving: false,
    }))

    .addCase("YEARCLASS_UPDATE_PUPIL_APPROACH", (state, action) => {
      const newApproach = action.payload

      const newPupils = state.pupils.map((pupil) => {
        if (pupil.id === newApproach.pupilId) {
          const newApproaches = upsertObjectInList(
            newApproach,
            pupil.approaches,
            ["subjectId"],
          )
          return { ...pupil, approaches: newApproaches }
        }
        return pupil
      })
      return {
        ...state,
        pupils: newPupils,
      }
    })

    .addCase("GET_YEARCLASS_TEST_MOMENT_APPROACHES_REQUEST", (state) => state)

    .addCase(
      "GET_YEARCLASS_TEST_MOMENT_APPROACHES_SUCCESS",
      (state, action) => {
        // theNotes should be an array of length 1
        const [theNotes, otherNotes] = partition(
          (note) => note.id === action.payload.noteId,
          state.yearclassTestMomentNotes,
        )

        return {
          ...state,
          yearclassTestMomentNotes: [
            ...otherNotes,
            ...theNotes.map((note) => ({
              ...note,
              relatedPupils: action.payload.response,
            })),
          ],
        }
      },
    )
    .addCase(
      "GET_GROUP-WORKSHEET__INSTRUCTION-GROUPS__PUPILS-APPROACHES_REQUEST",
      (state) => ({
        ...state,
        isApproachesLoading: true,
        approachesExpectedYear: null,
        approachesExpectedClassYear: null,
        approachesExpectedTestTime: null,
      }),
    )
    .addCase(
      "GET_GROUP-WORKSHEET__INSTRUCTION-GROUPS__PUPILS-APPROACHES_SUCCESS",
      (state, action) => ({
        ...state,
        isApproachesLoading: false,
        pupils: [
          ...state.pupils.map((pupil) => {
            const samePupilWithApproach = action.payload.pupils.find(
              (p) => pupil.id === p.id,
            )
            return { ...pupil, ...samePupilWithApproach }
          }),
        ],
        approachesExpectedYear: action.payload.year,
        approachesExpectedClassYear: action.payload.classYear,
        approachesExpectedTestTime: action.payload.testTimeExpected,
      }),
    )
    .addCase(
      "UPDATE_GROUP-WORKSHEET__INSTRUCTION-GROUPS__PUPILS-APPROACHES_REQUEST",
      (state) => ({
        ...state,
        isApproachesUpdating: true,
      }),
    )
    .addCase(
      "UPDATE_GROUP-WORKSHEET__INSTRUCTION-GROUPS__PUPILS-APPROACHES_SUCCESS",
      (state, action) => {
        const updatedPupils = [
          ...state.pupils.map((pupil) => {
            if (pupil.id === action.payload.id) {
              return {
                ...pupil,
                ...action.payload,
              }
            }
            return pupil
          }),
        ]
        return {
          ...state,
          pupils: updatedPupils,
          isApproachesUpdating: false,
        }
      },
    )
    .addCase("INSERT_CREATED_PUPIL_METHOD_TEST_NOTE", (state, action) => {
      return { ...state, methodNotes: [...state.methodNotes, action.payload] }
    })
    .addCase("INSERT_CREATED_PUPIL_NATIONAL_TEST_NOTE", (state, action) => {
      const updatedPupils = [...state.pupils].map((pupil) => {
        return {
          ...pupil,
          scores: pupil.scores.map((score) => {
            const scoreId = score.parent ? score.parent.id : score.id
            if (scoreId === action.payload.citoTestScoreId) {
              return { ...score, notes: [...score.notes, action.payload] }
            }
            return score
          }),
        }
      })

      return { ...state, pupils: updatedPupils }
    })
    .addCase("UPDATE_PUPIL_METHOD_TEST_NOTE", (state, action) => {
      return {
        ...state,
        methodNotes: [...state.methodNotes].map((note) => {
          if (note.id === action.payload.id) {
            return action.payload
          }
          return note
        }),
      }
    })
    .addCase("UPDATE_PUPIL_NATIONAL_TEST_NOTE", (state, action) => {
      const updatedPupils = [...state.pupils].map((pupil) => {
        return {
          ...pupil,
          scores: pupil.scores.map((score) => {
            const scoreId = score.parent ? score.parent.id : score.id
            if (scoreId === action.payload.citoTestScoreId) {
              return {
                ...score,
                notes: [...score.notes].map((note) => {
                  if (note.id === action.payload.id) {
                    return action.payload
                  }
                  return note
                }),
              }
            }
            return score
          }),
        }
      })

      return { ...state, pupils: updatedPupils }
    })
    .addCase("DELETE_PUPIL_METHOD_TEST_NOTE", (state, action) => {
      return {
        ...state,
        methodNotes: state.methodNotes.filter(
          (note) => note.id !== action.payload.id,
        ),
      }
    })
    .addCase("DELETE_PUPIL_NATIONAL_TEST_NOTE", (state, action) => {
      const updatedPupils = [...state.pupils].map((pupil) => {
        return {
          ...pupil,
          scores: pupil.scores.map((score) => {
            const scoreId = score.parent ? score.parent.id : score.id
            if (scoreId === action.payload.citoTestScoreId) {
              return {
                ...score,
                notes: score.notes.filter(
                  (note) => note.id !== action.payload.id,
                ),
              }
            }
            return score
          }),
        }
      })

      return { ...state, pupils: updatedPupils }
    })
})
