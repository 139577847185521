import { createReducer } from "@reduxjs/toolkit"

const initialState = {
  uploads: [],
  errors: {},
  isUploadsLoading: false,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("UPLOADS_FETCH_REQUEST", (state) => ({
      ...state,
      isUploadsLoading: true,
    }))
    .addCase("UPLOADS_FETCH_SUCCESS", (state, action) => ({
      ...state,
      isUploadsLoading: false,
      uploads: action.payload,
    }))
    .addCase("UPLOADS_FETCH_FAILURE", (state) => ({
      ...state,
      isUploadsLoading: false,
    }))
})
