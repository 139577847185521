import { createReducer } from "@reduxjs/toolkit"
import * as R from "ramda"

const initialState = {
  subjects: [],
  isLoading: true,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_SUBJECTS_REQUEST", (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase("GET_SUBJECTS_SUCCESS", (state, action) => ({
      ...state,
      subjects: R.sortBy(R.prop("position"))(action.payload),
      isLoading: false,
    }))
    .addCase("GET_SUBJECTS_FAILURE", (state) => ({
      ...state,
      isLoading: false,
    }))
})
