import { createReducer } from "@reduxjs/toolkit"

const initialState = {
  questions: [],
  domains: [],
  isQuestionsLoaded: false,
  isDomainsLoaded: false,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_SE_QUESTIONS_REQUEST", (state) => ({
      ...state,
      isQuestionsLoaded: false,
      isDomainsLoaded: false,
    }))
    .addCase("GET_SE_QUESTIONS_SUCCESS", (state, action) => {
      const [domains, questions] = action.payload

      return {
        ...state,
        questions,
        domains,
        isQuestionsLoaded: true,
        isDomainsLoaded: true,
      }
    })
})
