import { createReducer } from "@reduxjs/toolkit"

export const initialState = {
  tests: [],
  testSections: [],
  domains: [],
  testTypes: [],
  testsLoading: false,
  domainsLoading: false,
  testTypesLoading: false,
  error: null,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_GROUP_GOALS_TESTS_REQUEST", (state) => ({
      ...state,
      testsLoading: true,
    }))
    .addCase("GET_GROUP_GOALS_TESTS_SUCCESS", (state, action) => {
      const sections = action.payload.testSections.map((section) => ({
        id: section.id,
        code: section.code,
        testId: section.testId,
        subtypeId: section.SubType.id,
        subtypeName: section.SubType.name,
        goalId: section.Goal.id,
        goalName: section.Goal.name,
        goalDetails: section.Goal.details,
        domainId: section.Domain.id,
        domainName: section.Domain.name,
      }))
      return {
        ...state,
        tests: action.payload.tests,
        testSections: sections,
        testsLoading: false,
      }
    })
    .addCase("GET_GROUP_GOALS_TESTS_FAILURE", (state, action) => ({
      ...state,
      testsLoading: false,
      error: action.payload,
    }))
    .addCase("GET_GROUP_GOALS_DOMAINS_REQUEST", (state) => ({
      ...state,
      domainsLoading: true,
    }))
    .addCase("GET_GROUP_GOALS_DOMAINS_SUCCESS", (state, action) => ({
      ...state,
      domains: action.payload.rows,
      domainsLoading: false,
    }))
    .addCase("GET_GROUP_GOALS_DOMAINS_FAILURE", (state, action) => ({
      ...state,
      domainsLoading: false,
      error: action.payload,
    }))
    .addCase("GET_GROUP_GOALS_TEST_TYPES_REQUEST", (state) => ({
      ...state,
      testTypesLoading: true,
    }))
    .addCase("GET_GROUP_GOALS_TEST_TYPES_SUCCESS", (state, action) => ({
      ...state,
      testTypes: action.payload,
      testTypesLoading: false,
    }))
    .addCase("GET_GROUP_GOALS_TEST_TYPES_FAILURE", (state, action) => ({
      ...state,
      testTypesLoading: false,
      error: action.payload,
    }))
})
